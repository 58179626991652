<template>
  <v-layout>
    <v-main>
      <v-sheet color="transparent">
        <!-- <Toolbar/> -->
        <router-view :key="$route.fullPath" />
        <!-- <Footer></Footer> -->
      </v-sheet>
    </v-main>
  </v-layout>
</template>
<script>
export default {
  components: {
    // Toolbar,
    // Footer
  },
};
</script>
<style>
html,
body {
  overflow-y: auto;
}
</style>
